(function () {

    'use strict';

    const FILTER_SETTINGS = require('shared/json/filter-operators.json');

    class TableFilterItemCtrl {
        constructor($scope, tableService, moment, system, $translate) {
            this.moment = moment;
            this.$translate = $translate;
            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;
        }

        static get $inject() {
            return ["$scope", "tableService", "moment", "system", "$translate"];
        }

        $onInit() {
            this.column = this.column ? this.column : null;
            this.filterColumns = this.filterColumns ? this.filterColumns : [];
            this.clearFilter = this.clearFilter ? this.clearFilter : _.noop;
        }

        getOperators(type, choices) {
            if (!_.isEmpty(choices)) {
                return ["$in"];
            } else {
                return _.chain(FILTER_SETTINGS.operators)
                    .find({type: type})
                    .get('operators', [])
                    .value();
            }
        }

        handleDateValue(column) {
            column.value = column.operator == '$between' ? `${column.value_1}_${column.value_2}` : column.value_1;
        }

        handleValue(event, column) {
            if (column.operator == "$exists") column.value = true;
            if (column.operator == "$nexists") column.value = false;
            else {
                switch (_.get(column, 'type')) {
                    case 'text':
                        column.value = '';
                        break;
                    case  'number':
                        column.value = 0;
                        break;
                    case 'date':
                        column.value = !column.value ? this.moment().format(this.dateFormat) : column.value;
                        break;
                    case 'time':
                        column.value = this.moment().format(this.timeFormat);
                        break;
                    case 'time':
                        column.value = true;
                        break;
                }
            }
        }

        checkFilter(event, column) {
            if (!column['isFilter'] && !_.find(this.filterColumns, ['isFilter', true]) && this.filterApplied) this.clearFilter();
        }

        getColumnName(column) {
            switch (column['order_by']) {
                case 'getArticleStoreQte':
                    let l = _.split(column.label, '$$');
                    return `${this.$translate.instant(l[1])} ${l[2]}`;
                    break;
                default:
                    return this.$translate.instant(column['label']);
                    break;
            }
        }

    }

    module.exports = {
        bindings: {
            column: '=?',
            filterColumns: '=?',
            clearFilter: '=?',
            filterApplied: '=?',
        },
        template: require("shared/views/table-filter-item.html"), // or template
        controllerAs: "vm",
        controller: TableFilterItemCtrl,
    };

})();